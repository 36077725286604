<div mat-dialog-header>
  @if (data.bulk) {
    <h1>Are you sure you'd like to approve the selected records?</h1>
  } @else {
    <h1>
      Notes & Attachments <span> - {{ data.tradeId }}</span>
    </h1>
  }
</div>
<mat-dialog-content class="dialog-container row">
  <div>
    <mat-form-field class="w-100">
      <mat-label>Enter Notes Here</mat-label>
      <textarea
        matInput
        [placeholder]="'Enter Notes Here'"
        [(ngModel)]="note"
      ></textarea>
    </mat-form-field>
  </div>

  <div class="upload-case-files mb-3">
    <h4 class="mb-2"> Upload Case Files </h4>
    <input
      type="file"
      class="file-input"
      (change)="onFileSelected($event)"
      #fileUpload
    />
    <span class="mb-2">
      {{ fileName || 'No file selected yet' }}
    </span>
    <button
      *ngIf="!fileName"
      class="w-100"
      mat-stroked-button
      aria-label="Attach File"
      color="primary"
      (click)="fileUpload.click()"
    >
      <mat-icon>attach_file</mat-icon> Attach File
    </button>
    <button
      *ngIf="fileName"
      class="w-100 mb-3"
      mat-stroked-button
      aria-label="Choose a Different File"
      (click)="fileUpload.click()"
    >
      <mat-icon>upload</mat-icon> Choose a Different File
    </button>
    <button
      *ngIf="fileName"
      class="w-100 mb-3"
      mat-button
      aria-label="Remove File"
      color="warn"
      (click)="clearFile()"
    >
      <mat-icon>clear</mat-icon> Remove File
    </button>
  </div>

  @if (!data.bulk) {
    <div class="attached-files mb-2">
      <table>
        <thead>
          <tr>
            @for (col of caseFileCols; track col.label) {
              <th>{{ col.label }}</th>
            }
          </tr>
        </thead>
        <tbody>
          <!-- @for (
            tradeApproval of data.tradeDetail.Details.TradeHistoryTable;
            track tradeApproval
          ) { -->
          <tr>
            @for (col of caseFileCols; track col.label) {
              <td>{{ col.varName }}</td>
            }
          </tr>
          <!-- } -->
        </tbody>
      </table>
    </div>
  }
</mat-dialog-content>
<mat-dialog-actions class="row buttons">
  <button mat-raised-button (click)="cancel()">Cancel</button>
  <button mat-raised-button color="accent" (click)="save()">
    @if (data.bulk) {
      Confirm
    } @else {
      Save
    }
  </button>
</mat-dialog-actions>
