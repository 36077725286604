import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CaseManagementService } from '../../../case-management/case-management.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-trade-attachments-dialog',
  templateUrl: './trade-attachments-dialog.component.html',
  styleUrls: ['./trade-attachments-dialog.component.scss'],
})
export class TradeAttachmentsDialogComponent implements OnInit {
  note = '';
  fileName;
  fileNameDisplay;
  file;
  fileType;
  caseFileCols = [
    {
      varName: 'CreatedDate',
      label: 'Date',
    },
    {
      varName: 'Note',
      label: 'Note',
    },
    {
      varName: 'ReviewerID',
      label: 'Reviewer ID',
    },
    {
      varName: 'ReviewerID',
      label: 'Attachments',
    },
  ];

  constructor(
    public cms: CaseManagementService,
    public dialogRef: MatDialogRef<TradeAttachmentsDialogComponent>,
    private snacky: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    console.log('this', this);
    if (!this.data.bulk && this.data.tradeId) {
      this.cms
        .getRelatedCases(this.data.tradeId, ['Internal', 'Bulk'])
        .subscribe(resp => {
          console.log('Dialog Opened!', resp);
        });
    }
  }

  onFileSelected(event) {
    this.file = event.target.files[0];

    if (this.file) {
      this.fileName = this.file.name;
    }
  }

  clearFile() {
    this.fileName = null;
    this.fileNameDisplay = null;
    this.file = null;
    this.fileType = null;
  }

  save() {
    this.dialogRef.close();
    this.dialogRef.afterClosed().subscribe(closeResp => {
      if (this.data.bulk) {
        console.log('>>>>> bulk', closeResp);
      } else {
        if (this.note !== '' && this.note !== null) {
          const note = {
            CaseRecordId: this.data.tradeDetail.Details.RightBRIDGECase.CaseID,
            NoteText: this.note,
            NoteTypeId: 2,
          };
          console.log('>>>>>', closeResp);
          this.cms.addCaseRecordNote(note).subscribe(noteResp => {
            console.log('noteResp', noteResp);
            this.note = '';
          });
        }

        if (this.fileName) {
          const formData = new FormData();
          formData.append('File1', this.file);
          this.fileName = '';

          this.cms
            .saveCaseRecordFile(
              this.data.tradeDetail.Details.RightBRIDGECase.CaseID,
              formData
            )
            .subscribe(fileResp => {
              console.log('fileResp', fileResp);
              this.snacky.open(
                'File uploaded, please allow some time for it to appear in your new Case Management record.',
                'Close',
                {
                  duration: 3000,
                }
              );
            });
        }
      }
      this.dialogRef = null;
    });
  }

  cancel() {
    this.dialogRef.close('cancel');
    this.dialogRef.afterClosed().subscribe(() => {
      this.dialogRef = null;
    });
  }
}
